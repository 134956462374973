<template>
  <div class="order">
    <el-alert type="warning" class="alert" :closable="false" show-icon>
      <template #title>
        <div>1、会员商城下单列表</div>
        <div>2、订单状态有待付款，待发货，待收货，已完成，已关闭</div>
        <div>
          3、待付款订单取消后则为已关闭。待付款订单支付后则为待发货。待发货订单发货后则为待收货。待收货订单收货后则为已完成。
        </div>
      </template>
    </el-alert>
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
    >
      <!-- <div class="timetype">
        <span class="el-form-item__label">时间类型</span>
        <div class="el-form-item__content">
          <el-select>
            <el-option> </el-option>
          </el-select>
          <el-date-picker
            type="datetimerange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            range-separator="至"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            clearable
          />
        </div>
      </div> -->
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
      <el-button type="primary" @click="autoRefreshClick"
        >{{ autoRefresh ? '暂停' : '开启' }}自动刷新</el-button
      >
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #phone>
        <el-table-column label="手机尾号" align="center">
          <template #default="scoped">
            {{ initTel(scoped.row.phone) }}
          </template>
        </el-table-column>
      </template>
      <template #orderType>
        <el-table-column label="支付方式" align="center">
          <template #default="scoped">
            {{ initOrderType(scoped.row.orderType) }}
          </template>
        </el-table-column>
      </template>
      <template #deliveryType>
        <el-table-column label="配送方式" align="center">
          <template #default="scoped">
            {{ initDeliveryType(scoped.row.deliveryType) }}
          </template>
        </el-table-column>
      </template>
      <template #orderGoodsList>
        <el-table-column label="商品信息" align="center" min-width="180">
          <template #default="scoped">
            <div
              v-for="(item, i) in scoped.row.orderGoodsList"
              :key="i"
              class="goodsinfo"
            >
              <img :src="item.goodsImage" />
              <span>{{ item.goodsName }}</span>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #payMoney>
        <el-table-column label="实付款" align="center">
          <template #default="scoped">
            <span v-if="scoped.row.orderType == 1">{{ scoped.row.payMoney ? scoped.row.payMoney+'元' : '-' }}</span>
            <span v-if="scoped.row.orderType == 2">{{ scoped.row.deductionIntegral }}积分</span>
            <span v-if="scoped.row.orderType == 3">{{ scoped.row.deductionIntegral }}积分+{{ scoped.row.payMoney  }}元</span>
          </template>
        </el-table-column>
      </template>
      <template #payIs>
        <el-table-column label="支付状态" align="center">
          <template #default="scoped">
            {{ payIs(scoped.row.payIs) }}
          </template>
        </el-table-column>
      </template>
      <template #orderStatus>
        <el-table-column label="订单状态" align="center">
          <template #default="scoped">
            {{ orderStatus(scoped.row.orderStatus) }}
          </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" width="300" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="detailsOrder(scoped.row)"
            >
              订单详情
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="remarkChange(scoped.row)"
            >
              订单备注
            </el-button>
            <el-button
              type="primary"
              size="small"
              v-if="scoped.row.cancelBtn == 1"
              @click="cancelOrder(scoped.row)"
            >
              取消
            </el-button>
            <el-button
              type="primary"
              size="small"
              v-if="scoped.row.deleteBtn == 1"
              @click="deleteOrder(scoped.row)"
            >
              删除
            </el-button>
            <el-button
              type="primary"
              size="small"
              v-if="scoped.row.deliverBtn == 1"
              @click="deliverOrder(scoped.row)"
            >
              发货
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="shipVisible"
      title="发货信息"
      width="40%"
      :before-close="shipClose"
    >
      <el-form
        ref="shipRuleFormRef"
        :model="shipForm"
        :rules="shipFormRules"
        label-width="120px"
        class="shipForm"
      >
        <el-form-item label="物流公司" prop="company">
          <el-input
            v-model="shipForm.company"
            :maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="物流订单号" prop="orderNum">
          <el-input
            v-model="shipForm.orderNum"
            placeholder="请输入"
            :maxlength="256"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="shipSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="remarkVisible"
      title="商家备注"
      width="40%"
      :before-close="remarkClose"
    >
      <el-form
        ref="ruleFormRef"
        :model="remarkForm"
        :rules="remarkFormRules"
        label-width="80px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="remarkForm.remark"
            type="textarea"
            :rows="5"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="remarkSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs, ref } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  ordersQueryCriteria,
  ordersCancel,
  ordersDel,
  ordersRemarks,
  ordersSendDelivery
} from '@/api/orderManagement/orderList'
import { useRouter } from 'vue-router'
const router = useRouter()
const ruleFormRef = ref()
const shipRuleFormRef = ref()
const initData = reactive({
  formLabels: [
    {
      title: '订单搜索',
      inputLabel: 'keyword',
      selectLabel: 'status1',
      type: 'makeup',
      options: [
        { value: 2, label: '订单编号' },
        { value: 1, label: '商品编号' },
        { value: 0, label: '商品名称' }
      ],
      placeholder: '请选择'
    },
    {
      title: '支付状态',
      label: 'payIs',
      type: 'options',
      options: [
        { value: 0, label: '未支付' },
        { value: 1, label: '已支付' }
      ],
      placeholder: '请选择'
    },
    {
      title: '订单状态',
      label: 'orderState',
      type: 'options',
      options: [
        { value: 1, label: '待付款' },
        { value: 2, label: '待发货' },
        { value: 3, label: '已发货' },
        { value: 4, label: '待核销' },
        { value: 5, label: '已完成' },
        { value: 6, label: '已取消' }
      ],
      placeholder: '请选择'
    }
    // {
    //   title: '订单类型',
    //   label: 'orderType',
    //   type: 'options',
    //   options: [
    //     { value: 3, label: '混合订单' },
    //     { value: 2, label: '积分订单' }
    //   ],
    //   placeholder: '请选择'
    // }
  ],
  fromData: {
    // 表单数据
    keyword: '',
    payIs: '',
    orderState: '',
    orderType: ''
  },
  formColumn: 4,
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    { prop: 'orderSn', label: '订单编号', width: 200 },
    { prop: 'nickname', label: '买家昵称' },
    { slot: 'phone' },
    { slot: 'orderType' },
    { slot: 'payMoney' },
    { prop: 'addressContact', label: '收货人' },
    { slot: 'deliveryType' },
    { slot: 'orderGoodsList' },
    { slot: 'payIs' },
    { slot: 'orderStatus' },
    { slot: 'action' }
  ],
  remarkVisible: false,
  remarkForm: {
    remark: '',
    id: ''
  },
  remarkFormRules: {
    remark: [
      {
        required: true,
        message: '请输入备注',
        trigger: 'blur'
      }
    ]
  },
  shipVisible: false,
  shipForm: {
    company: '',
    orderNum: '',
    id: ''
  },
  shipFormRules: {
    company: [
      {
        required: true,
        message: '请输入物流公司',
        trigger: 'blur'
      }
    ],
    orderNum: [
      {
        required: true,
        message: '请输入物流订单号',
        trigger: 'blur'
      }
    ]
  },
  autoRefresh: false,
  timer: null
})

onMounted(() => {
  getDataList()
  // timer.value = setInterval(() => {
  //   getDataList()
  // }, 10000)
})

// 重置
const reset = () => {
  fromData.value = {
    keyword: '',
    payIs: '',
    orderState: '',
    orderType: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keyword !== '') {
    data.keyword = fromData.value.keyword
  }
  if (fromData.value.payIs !== '') {
    data.payIs = fromData.value.payIs
  }
  if (fromData.value.orderState !== '') {
    data.orderState = fromData.value.orderState
  }
  if (fromData.value.orderType !== '') {
    data.orderType = fromData.value.orderType
  }
  ordersQueryCriteria(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 订单详情
const detailsOrder = row => {
  router.push({
    path: '/orderDetails',
    query: {
      id: row.id
    }
  })
}

function initTel(phone) {
  if (phone) {
    return `*******${phone.substr(-4)}`
  } else {
    return ''
  }
}

const autoRefreshClick = () => {
  if (autoRefresh.value) {
    clearInterval(timer.value)
    timer.value = null
    autoRefresh.value = false
  } else {
    autoRefresh.value = true
    timer.value = setInterval(() => {
      getDataList()
    }, 10000)
  }
}

// 取消
const cancelOrder = row => {
  ElMessageBox.confirm('是否确认取消订单?', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      ordersCancel({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 删除
const deleteOrder = row => {
  ElMessageBox.confirm('是否确认订单?', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      ordersDel({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 发货
const deliverOrder = row => {
  shipForm.value.id = row.id
  shipVisible.value = true
}

// 发货提交
const shipSubmit = async () => {
  if (!shipRuleFormRef.value) return
  await shipRuleFormRef.value.validate((valid, fields) => {
    if (valid) {
      const data = {
        orderId: shipForm.value.id,
        expressName: shipForm.value.company,
        invoiceNo: shipForm.value.orderNum
      }
      ordersSendDelivery(data).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          shipVisible.value = false
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 发货关闭
const shipClose = () => {
  if (!shipRuleFormRef.value) return
  shipRuleFormRef.value.resetFields()
  shipVisible.value = false
}

// 备注
const remarkChange = row => {
  remarkForm.value.remark = row.shopRemark
  remarkForm.value.id = row.id
  remarkVisible.value = true
}

// 备注提交
const remarkSubmit = async () => {
  if (!ruleFormRef.value) return
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      ordersRemarks({
        id: remarkForm.value.id,
        remarks: remarkForm.value.remark
      }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          remarkVisible.value = false
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 备注关闭
const remarkClose = () => {
  if (!ruleFormRef.value) return
  ruleFormRef.value.resetFields()
  remarkVisible.value = false
}

// 支付状态
const payIs = val => {
  let v = ''
  switch (val) {
    case 0:
      v = '未支付'
      break
    case 1:
      v = '已支付'
      break
  }
  return v
}

// 订单状态
const orderStatus = val => {
  let v = ''
  switch (val) {
    case 1:
      v = '待付款'
      break
    case 2:
      v = '待发货'
      break
    case 3:
      v = '已发货'
      break
    case 4:
      v = '待核销'
      break
    case 5:
      v = '已完成'
      break
    case 6:
      v = '已取消'
      break
  }
  return v
}

// 支付方式
const initOrderType = val => {
  let v = ''
  switch (val) {
    case 1:
      v = '现金支付'
      break
    case 2:
      v = '积分支付'
      break
    case 3:
      v = '混合支付'
      break
  }
  return v
}


// 配送方式
const initDeliveryType = val => {
  let v = ''
  switch (val) {
    case 1:
      v = '快递包邮'
      break
    case 2:
      v = '门店自提'
      break
    case 3:
      v = '立即发放'
      break
  }
  return v
}
const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  remarkVisible,
  remarkForm,
  remarkFormRules,
  shipVisible,
  shipForm,
  shipFormRules,
  autoRefresh,
  timer
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.order {
  .alert {
    margin-bottom: 20px;
  }

  .timetype {
    display: flex;
    width: 416px;
    padding-right: 10px;
    box-sizing: border-box;

    span {
      width: 100px;
    }

    .el-select {
      width: 33%;
    }

    :deep(.el-date-editor) {
      width: 67%;
    }
  }

  .goodsinfo {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    img {
      width: 60px;
      height: 60px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .shipForm {
    width: 80%;
  }
}
</style>
